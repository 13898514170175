/* body{
    background-color: #f4f3ef !important;
    font-family: 'Raleway';
}

.gg-log-in{
    --ggs: 0.8;
    margin-right: 10px;
}

.gg-log-out{
    --ggs: 0.8;
    margin-right: 15px;
    margin-left: 5px;
}

.gg-timer{
    --ggs: 0.8;
    margin-right: 5px;
}

.gg-home{
    --ggs: 0.8;
    margin-right: 5px;
}


.gg-add{
    --ggs: 0.8;
    margin-right: 5px;
}

.gg-add-r 
{
    --ggs: 0.8;
    margin-right: 5px;
}
.gg-home
{
    --ggs: 0.8;
    margin-right: 5px;
    margin-left: 2px;
    
}

.gg-profile
{
    --ggs: 0.8;
    margin-right: 5px;
}

.gg-controller
{
    --ggs: 0.7;
    margin-right: 12px;
    margin-left: 5px;
}

.gg-list{
    --ggs: 0.7;
    margin-right: 5px;
    
}

.gg-calendar{
    --ggs: 0.8;
    margin-right: 5px;
}

.MuiButton-root{
    font-size: 13px !important
}

h1{
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Raleway';
    color: #DC581F;
}
p{
    margin-top: 10px; 
    font-family: 'Raleway';
    font-size: 14px;
}

.MuiPaper-root{
    padding: 20px; 
    margin-top: 10px; 
}

.MuiGrid-item{
    padding: 10px;
}

.MuiButton-label{   
    font-weight: 900;
    font-size: 10px;
    color: white;
}

.MuiToolbar-regular{
    padding: 0px !important;
    margin-top: -20px;
    margin-bottom: -20px;
}

.MuiAppBar-colorPrimary{
    color: white !important;
    background-color: #1D1D1B !important;
}

.MuiButton-containedPrimary {
    background-color: #1D1D1B !important;
}

.leftMenu{
    background-color: grey;
}

.leftMenu .MuiButton-label{
    color: white;
    font-size: 10px;
    
}

.menuHeadline{
    color: white;
    font-size: 14px;
    margin-left: 10px;
    font-weight: 00;
    margin-bottom: 10px;
    margin-top: 10px;
}

.MuiPaper-rounded{
    border-radius: 14px !important;
} */

.gg-add{
    --ggs: 0.8;
    margin-right: 5px;
}

.gg-log-in{
    --ggs: 0.8;
    margin-right: 5px;
}

.MuiPaper-root{
    margin-top: 20 !important;
    padding-left: 20 !important;
    padding-top: 1 !important;
    padding-bottom: 1 !important;
}


.MuiAppBar-colorPrimary{
    color: white !important;
    background-color: #1D1D1B !important;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
}

.headline{
    color: "red" !important;
}